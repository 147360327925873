<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Статистика: Клиенты</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Клиенты</h5>
        </div>

        <div class="d-flex flex-column justify-content-center">
          <a-input ref="searchInput" v-model="search" placeholder="Поиск">
            <a-icon slot="prefix" type="search" />
            <a-tooltip slot="suffix" title="Поиск по ID, ФИО, номеру телефона, почте">
              <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </a-input>
        </div>
      </div>

      <div class="card-body">
        <div class="users_statistics">
          <h4 class="mb-3">Статистика пользователей</h4>
          <div class="users_statistics--item" v-for="stat in usersStatistics" :key="stat.id">
            <div @click="date = stat.id">{{ stat.name }}</div>
            <div>{{ getCount(stat.alias) }}</div>
          </div>
        </div>

        <clients-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" :isStatistics="true" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientsTable from '@/components/users/ClientsTable'

import usersStatistics from '@/entities/statistics'

export default {
  name: 'index',

  components: {
    ClientsTable,
  },

  data() {
    return {
      list: [],
      search: '',
      statistics: {},
      date: null,
      usersStatistics,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },

  created() {
    this.getList()
  },

  watch: {
    date() {
      this.getList()
    },

    search() {
      this.getList()
    },
  },

  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page,
        ...this.date ? { date: this.date } : {},
        ...this.search ? { search: this.search } : {},
      }

      const users = (await this.$services.get('admin/statistics', { params })).data.data
      // eslint-disable-next-line camelcase
      const { today, week, month, three_month } = users
      this.statistics = {
        today,
        week,
        month,
        three_month,
      }

      this.list = users.users.data
      this.pagination = {
        current: users.users.pagination.current_page,
        total: users.users.pagination.total,
        pageSize: users.users.pagination.per_page,
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
    getCount(alias) {
      return this.statistics[alias] || 0
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  .users_statistics {
    max-width: 313px;
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    &--item {
      display: flex;
      justify-content: space-between;
      color: rgba(0,0,0,.87);
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 4px;
      div:first-child {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
