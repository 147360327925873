export default [
  {
    name: 'За сегодня',
    alias: 'today',
    id: 1,
  },
  {
    name: 'За неделю',
    alias: 'week',
    id: 2,
  },
  {
    name: 'За месяц',
    alias: 'month',
    id: 3,
  },
  {
    name: 'За 3 месяца',
    alias: 'three_month',
    id: 4,
  },
]
